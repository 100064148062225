// 
// _dashboard.scss
// 

.dash-filter-picker {
    min-width: 210px !important;
}




.crm-widget {
    .col {
        border-right: 1px solid $border-color;
        &:last-child {
            border: 0px;
        }
        @media (min-width: 768px) and (max-width: 1399.98px) { 
            &:nth-child(3) {
                border-right: 0px;
            }
            &:last-child { 
                border-right: 1px solid $border-color;
            }
         }
        @media (max-width: 767.98px) {
            border-right: 0px;
            border-bottom: 1px solid $border-color;
        }
    }
}   


@media (min-width: 1400px) and (max-width: 1599.98px) { 
    .project-wrapper {
        >.col-xxl-8, .col-xxl-4{
            width: 100%;
        }
    }
}

.crypto-widget {
    max-width: 130px !important;
}

//nft dashboard

.bg-marketplace {
    background-image: url(../../images/nft/marketplace.png);
    background-size: cover;
}

.dash-countdown {
    .countdownlist {
        .count-num {
            background-color: $card-bg;
            padding: 16px 8px;
            font-size: 22px;

            @media (max-width: 575.98px) {
                font-size: 16px;
                padding: 8px 6px;
            }
        }

        .count-title {
            @media (max-width: 575.98px) {
                font-size: 10px;
            }
        }
    }
}

.marketplace-icon {
    position: absolute;
    float: right;
    top: 30px;
    left: 30px;
}

.marketplace-swiper {
    .swiper-button-next,
    .swiper-button-prev {
        top: 34px;
        width: 28px;
        height: 28px;
        background-color: rgba($primary, 0.1);
        color: $primary;
        border-radius: .3rem;
        right: 16px !important;
    }
    .swiper-button-prev {
        right: 58px !important;
        left: auto !important;
    }
}

.dash-collection {
    .content{
        background-color: rgba($white, 0.25);
        backdrop-filter: blur(5px);
    }
}

.dash-nft{
    @media (max-width: 1441.98px) {
        .col-xxl-9, .col-xxl-3 {
            width: 100% !important;
            max-width: 100% !important;
        }
    }
}

    // .jvm-markers-group {
    //     image {
    //         height: 20px !important;
    //         width: 20px !important;
    //     }
    // }

        .jvectormap-legend-cnt-h .jvectormap-legend-tick-sample {
            width: 32px;
            height: 32px;
            display: inline-block;
            vertical-align: middle;
        }