@import '../scss/icons.scss';
@import '../scss/config/default/bootstrap.scss';
@import '../scss/config/default/app.scss';
@import '../scss/config/default/custom.scss';
@import './shepherd.css';
//@import './mermaid.min.css';

//RTL
// @import "../scss/rtl/components-rtl";
// @import "../scss/rtl/layouts-rtl";
// @import "../scss/rtl/plugins-rtl";
// @import "../scss/rtl/pages-rtl";

//Rigthsidebar offcanvas
.offcanvas-header-dark {
  .btn-close {
    filter: invert(1) grayscale(100%) brightness(200%);
  }
}

body {
  * {
    outline: none;
  }
}

.col60 {
  width:60px;
}

.col120 {
  width:120px;
}

.col130 {
  width:130px;
}

.col150 {
  width:150px;
}

.col200 {
  width:200px;
}

.col360 {
  width:360px;
}

@media screen and (max-width: 1515px) {
  .col60 {
    width:60px;
  }

  .col120 {
    width:120px;
  }

  .col130 {
    width:130px;
  }

  .col150 {
    width:150px;
  }

  .col200 {
    width:120px;
  }

  .col360 {
    width:200px;
  }
  
}


ul.checkmark-ul {
  list-style: none;
}

ul.checkmark-ul > li:before {
  content: '✓';
  margin-right:10px;
}

[data-layout="horizontal"] .navbar-menu .navbar-nav .nav-link.current-page {
  font-weight:bold;
}

body .navbar-menu .navbar-nav .nav-link.active {
  font-weight:bold!important;
}

.help-center-header {
  font-size:18px;
  text-decoration:underline;
  font-weight: 700;
  margin:40px 0 20px 0;
}

.container-fluid iframe {
  max-width:100%;
}

.auth-page-content .contactFormContainer {
  margin-top:100px;
}

.issued-color {
  background-color:#3a3b8b;
}

.sort-button {
  display:inline-block;
}

.card-body {
  font-size: 14px;
}

.card-header h2 {
  font-weight:700;
}

.sort-arrows-container {
  display:flex;
  flex-direction:column;
  align-items:center;
  justify-content:center;
  margin-top:0px;
}

.sort-arrows-container i:last-child {
  margin-top:-10px;
}

.sort-button i {
  opacity:0.5;
}

.sort-button i.arrow-solid {
  opacity:1;
}

.retired-color {
  background-color:#6162bd;
}

.bring-to-front {
  position:relative;
  z-index: 9999;
}

.respondents-only {
  
  a {
    cursor:default;
  }

  i {
    opacity:0.5;
  }

  span {
    opacity:0.5;
  }

  span.tooltiptext {
      opacity:1;
      padding:5px;
  }

}

.orange-border {
  border-color: #DC6B2F!important;
}

.noUi-horizontal .noUi-handle {
  height: 16px !important;
  width: 16px !important;
  border-radius: 50% !important;
  right: -10px !important;
  top: -7px !important;
}

.noUi-connect,
.noUi-horizontal {
  height: 2px !important;
}

.noUi-handle {
  background: #0ab39c !important;
  border: 2px solid var(--vz-card-bg) !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.noUi-target {
  background: #fafafa !important;
  border-radius: 4px !important;
  border: 1px solid #d3d3d3 !important;
  background-color: var(--vz-light) !important;
  border-color: var(--vz-light) !important;
  box-shadow: none !important;
  height: 4px !important;
}

.dropzone .dz-message {
  text-align: center;
}

i.search-icon {
  background-image:url("../images/icons/search-icon.png");
  background-repeat:no-repeat;
  background-position:50% 50%;
  background-size:100% auto;
  display:inline-block;
  width:14px;
}

// //Calendar
.fc-direction-ltr .fc-daygrid-event.fc-event-end,
.fc-direction-rtl .fc-daygrid-event.fc-event-start {
  padding: 5px !important;
  font-size: 12px !important;
  margin-right: 2px;
}

.fc-event {
  border: none !important;
}

@include media-breakpoint-down(sm) {
  .fc-toolbar {
    display: block !important;
    text-align: center;

    .fc-left,
    .fc-right,
    .fc-center {
      float: none;
      display: block;
      clear: both;
      margin: 10px 0;
    }
  }

  .fc {
    .fc-toolbar {
      > * {
        > * {
          float: none;
        }
      }
    }
  }

  .fc-today-button {
    display: none;
  }
}


.ql-container.ql-snow {
  height: 250px;
}

.rangeslider-horizontal .rangeslider__fill {
  background-color: #405189 !important;
}

// .Toastify__toast {
//   min-height: 43px !important;
//   max-height: 43px !important;
// }

@each $color, $value in $theme-colors {
  .simplebar-track-#{$color} {
    .simplebar-scrollbar:before {
      background: $value !important;
    }
  }
}

// chat
.chat-conversation .chat-list .message-box-drop {
  .nav-btn {
    font-size: 18px;
    padding: 4px;
    color: $gray-600;
  }
}

// authentication

#tsparticles {
  canvas {
    position: absolute !important;
  }
}

.alert-msg-show {
  right: 24px;
  top: 40px;
  z-index: 1005;
}

// Emoji Picker

.pickerEmoji {
  position: absolute;
  bottom: -100px;
  left: 10%;
  transform: translateX(-50%);
}

.Toastify {
  .Toastify__toast {
    min-height: 43px;
  }

  .Toastify__close-button {
    top: 6px;
  }

  .Toastify__close-button--light {
    color: $light;
  }
}

//Page Not Found
#notfound {
  position: relative;
  height: 100vh;
}

#notfound .notfound {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.notfound {
  max-width: 520px;
  width: 100%;
  line-height: 1.4;
  text-align: center;
}

.notfound .notfound-404 {
  position: relative;
  height: 240px;
}

.notfound .notfound-404 h1 {
  font-family: 'Montserrat', sans-serif;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  font-size: 252px;
  font-weight: 900;
  margin: 0px;
  color: #262626;
  text-transform: uppercase;
  letter-spacing: -40px;
  margin-left: -20px;
}

.notfound .notfound-404 h1 > span {
  text-shadow: -8px 0px 0px #fff;
}

.notfound .notfound-404 h3 {
  font-family: 'Cabin', sans-serif;
  position: relative;
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
  color: #262626;
  margin: 0px;
  letter-spacing: 3px;
  padding-left: 6px;
}

.notfound h2 {
  font-family: 'Cabin', sans-serif;
  font-size: 20px;
  font-weight: 400;
  text-transform: uppercase;
  color: #000;
  margin-top: 0px;
  margin-bottom: 25px;
}

// CUSTOM CHANGES
.reset_wrapper{
  position: relative;
  .response_message_reset{
    position: relative;
    background-color: #f5c2c7;
    padding: 10px 0px 0px 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 15px;
    transition: all;
    border-radius: 10px;

    &.hide{
      display: none;
    }
    h5{
      color:#842029;
    }
  }
}

// .trade_list{
//   background-color: cyan;
//   .trade_list_details{
//     display: flex;

//     .searcher{
//       position: relative;
//       right: 10px;

//       .dropdown{
//         background-color:#343a40;
//         width: 20%;
//         position: absolute;
//         color: white;
//         .dropdown_btn{
//           background-color: #343a40;
//           padding: 10px;
//           border-radius: 10px;
//           color: white;
//         }
//         div.menus{
//           display: none;
//           width: 100%;
          
//           &.show{
//             display: flex;
//             flex-direction: column;
            
//           }
          
//         }
//         ul{
//           margin-bottom: .5rem;
//           padding: 5px;
//           border-radius: 10px;
//         }
//         li{
//         list-style: none;
//         position: relative;
//         left: 0;
//           a{
//             color: white;

//           }
//         }
//         }
//       }
//     }

//     .searcher_form{
//       position: absolute;
//       right: 0px;
//     }


//   }


// END CUSTOM CHANGES

@media only screen and (max-width: 767px) {
  .notfound .notfound-404 {
    height: 200px;
  }
  .notfound .notfound-404 h1 {
    font-size: 200px;
  }
}

@media only screen and (max-width: 480px) {
  .notfound .notfound-404 {
    height: 162px;
  }
  .notfound .notfound-404 h1 {
    font-size: 162px;
    height: 150px;
    line-height: 162px;
  }
  .notfound h2 {
    font-size: 16px;
  }
}
